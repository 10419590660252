import React from 'react';
import { Alert, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { Dictionary, IFormGroup, Message } from '../types/common';

export const Loading: React.FC<Dictionary<string>> = ({ message }): JSX.Element => (
  <div className="mx-auto text-center">
    <FontAwesomeIcon icon={faCircleNotch} spin size="2x" />
    <div>{ message }</div>
  </div>
);

export const formatDate = (date: Date): any => format(new Date(date), 'yyyy-MM-dd HH:mm:ss');

export const FlashMessage: React.FC<Message> = ({ variant, text }): JSX.Element => (
  <Alert variant={variant}>{ text }</Alert>
);

export const FormGroup: React.FC<IFormGroup> = ({
  name, type, value = '', onChange, placeholder, text = '',
}): JSX.Element => {
  const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
  return (
    <Form.Group>
      <Form.Label>
        { capitalizedName }
        :
      </Form.Label>
      <Form.Control
        name={name}
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
      { text
      && (
      <Form.Text className="text-muted">
        { text }
      </Form.Text>
      )}
    </Form.Group>
  );
};
