import React from 'react';
import Top from '../components/lp/Top';
import Features from '../components/lp/Features';
import HowToUse from '../components/lp/HowToUse';
import FAQ from '../components/lp/FAQ';

const Home: React.FC = (): JSX.Element => (
  <>
    <Top />
    <Features />
    <HowToUse />
    <FAQ />
  </>
);

export default Home;
