import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = (): JSX.Element => (
  <footer className="bg-light text-center text-lg-start">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6 mb-4 mb-md-0">
          <ul className="list-unstyled mb-0">
            <Link to="/tos"><li className="text-dark">利用規約</li></Link>
            <Link to="/privacy"><li className="text-dark">プライバシーポリシー</li></Link>
          </ul>
        </div>

        <div className="col-lg-6 col-md-6 mb-4 mb-md-0">
          <ul className="list-unstyled mb-0">
            <li>
              <a
                href="https://docs.google.com/forms/d/1jj35F_dZLYpno8Lxa54DDiqlKlkbkSFUzcQ0sT_A7eY/edit"
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                お問い合わせ
              </a>
            </li>
            <li>
              <a
                href="https://vertechc.com/"
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                運営会社
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="text-center p-3">
      © 2021 Copyright:
      <a className="text-dark" href="https://vertechc.com/">Vertech Consulting K.K.</a>
    </div>
  </footer>
);

export default Footer;
