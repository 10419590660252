import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  name: string;
}

export const FormLabel: React.FC<Props> = (props): JSX.Element => {
  const { name } = props;
  return (
    <Form.Label>
      {name}
    </Form.Label>
  );
};
