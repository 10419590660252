import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { Group } from '../../types/group';
import {
  failGroup, fetchingFinishedGroup, updateGroup,
} from '../actions/groups';
import {
  save as calendarSave, update as calendarUpdate, destroy as calendarDestroy,
} from '../../services/calendars';
import { RootState } from '../../types/store';
import { Calendar } from '../../types/calendar';

export const save = (calendar: Calendar) => async (
  dispatch: Dispatch, getState: RootState,
): Promise<void> => {
  dispatch(fetchingFinishedGroup());
  const { groups } = getState();

  if (calendar.title === '' || calendar.description === '') {
    dispatch(failGroup({ message: 'タイトルと内容を入力してください！', status: '' }));
  }
  try {
    const group = groups.data.find((g: Group) => g.id === Number(calendar.group_id));

    const response = await calendarSave(calendar);
    const createdCalendar = response.data as Calendar;
    const newGroup: Group = { ...group };
    newGroup.calendars.push(createdCalendar);
    dispatch(updateGroup(newGroup));

    dispatch(fetchingFinishedGroup());
    dispatch(push(`/groups/${group.id}/calendars/${createdCalendar.id}`));
  } catch (error) {
    dispatch(failGroup({ message: error.message, status: '' }));
  }
};

export const update = (calendar: Calendar) => async (
  dispatch: Dispatch, getState: RootState,
): Promise<void> => {
  dispatch(fetchingFinishedGroup());
  const { groups } = getState();

  if (calendar.title === '' || calendar.description === '') {
    dispatch(failGroup({ message: 'タイトルと内容を入力してください！', status: '' }));
  }

  try {
    const group = groups.data.find((g: Group) => g.id === Number(calendar.group_id));

    const response = await calendarUpdate(calendar);
    const updatedCalendar = response.data as Calendar;
    const newGroup: Group = { ...group };

    const updatedBoardOfGroup = group?.calendars.find(
      (c: Calendar) => c.id === Number(updatedCalendar.id),
    );
    const index = group.calendars.indexOf(updatedBoardOfGroup);
    const editedGroup: Group = { ...group };

    editedGroup.calendars[index] = updatedCalendar;

    newGroup.calendars.push(updatedCalendar);
    dispatch(updateGroup(newGroup));
    dispatch(fetchingFinishedGroup());
    dispatch(push(`/groups/${group.id}/calendars/${updatedCalendar.id}`));
  } catch (error) {
    dispatch(failGroup({ message: error.message, status: '' }));
  }
};

export const destroy = (calendar: Calendar) => async (
  dispatch: Dispatch, getState: RootState,
): Promise<void> => {
  const { groups } = getState();
  await calendarDestroy(calendar.id);
  const group = groups.data.find((g: Group) => g.id === Number(calendar.group_id));
  const newGroup: Group = { ...group };
  const targetCalendar: Calendar = newGroup
    .calendars.filter((c: Calendar) => c.id === calendar.id)[0];
  const index = newGroup.calendars.indexOf(targetCalendar);
  newGroup.calendars.splice(index, 1);
  dispatch(updateGroup(newGroup));
};
