import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

const Features: React.FC = (): JSX.Element => (
  <section>
    <div className="top">
      <Container>
        <div className="text-section py-5">
          <h1 className="mt-4 font-weight-bold bg-white-opacity p-2">
            見えない出会いを発見し&nbsp;&nbsp;
            <br />
            組織の可能性を解き放とう！
          </h1>
          <p className="bg-white-opacity p-2 mt-4">
            <span className="d-inline">
              ラクカンは『会員情報』と『マップ』を組み合わせることでコミュニティのエンゲージメント運営を推進する会員管理ツールです
            </span>
          </p>
          <div className="mt-4 text-center text-md-left">
            <div className="btn-wrap">
              <a
                href="https://docs.google.com/forms/d/1JNZCwUnIzjGNxi3DzZjlhnZhgjRB_JF3vgpjbi_U3OY/viewform?edit_requested=true"
                target="_blank"
                rel="noreferrer"
                className="btn btn-c"
              >
                <span>簡単30秒 新規登録はコチラ！</span>
                <br />
                コミュニティ運営アカウント新規作成
                <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </section>
);

export default Features;
