import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { FlashMessage, FormGroup, Loading } from '../../../components/common';
import userService from '../../../services/users';
import { Message } from '../../../types/common';
import { Group } from '../../../types/group';
import { updateGroup } from '../../../redux/actions/groups';
import { User } from '../../../types/user';
import invitationService from '../../../services/invitations';
import { Company } from '../../../types/company';

const UserForm: React.FC = (props: any): JSX.Element => {
  const [id, setId] = useState<number|null>(null);
  const [group, setGroup] = useState({} as Group);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [companyTitle, setCompanyTitle] = useState<string>('');
  const [companyAddress, setCompanyAddress] = useState<string>('');
  const [companyLong, setCompanyLong] = useState<string>('');
  const [companyLat, setCompanyLat] = useState<string>('');

  const [redirect, setRedirect] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message>({} as Message);
  const { data } = useSelector((state: any) => state.groups);

  const dispatch = useDispatch();
  const updateGroupAction = (g: Group) => dispatch(updateGroup(g));

  useEffect(() => {
    const newGroup = data.find((g: Group) => g.id === Number(props.match.params.groupId));
    setGroup(newGroup);
    if (props.match.params.id !== 'new') {
      const newUser = newGroup.users?.find((u: User) => u.id === Number(props.match.params.id));

      setId(newUser?.id);
      setName(newUser?.name);
      setCompanyId(newUser?.company?.id);
      setCompanyName(newUser?.company?.name);
      setCompanyTitle(newUser?.company?.title);
      setCompanyAddress(newUser?.company?.address);
      setCompanyLong(newUser?.company?.long);
      setCompanyLat(newUser?.company?.lat);
    }
  }, []);

  const add = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (name === '') {
      setMessage({ variant: 'danger', text: '名前の入力は必須です' });
      setLoading(false);
      return;
    }

    try {
      const response = await invitationService.invite(group.id, name, email);
      const user = response.data as User;
      const newGroup: Group = { ...group };
      newGroup.users.push(user);

      await updateGroupAction(newGroup);
      setLoading(false);
      setMessage({ variant: 'success', text: 'ユーザーを追加しました' });
      if (group) {
        setRedirect(true);
      }
    } catch (error) {
      setMessage({ variant: 'danger', text: error.message });
      setLoading(false);
    }
  };

  const update = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (name === '') {
      setMessage({ variant: 'danger', text: '名前の入力は必須です' });
      setLoading(false);
      return;
    }

    try {
      const response = await userService.update({
        id,
        name,
        company: {
          id: companyId,
          name: companyName,
          title: companyTitle,
          address: companyAddress,
          long: companyLong,
          lat: companyLat,
        } as Company,
      }, group?.id);
      const updatedUser = await response.data as User;
      const updatedUserOfGroup = group?.users.find((u: User) => u.id === Number(updatedUser.id));
      // @ts-ignore
      const index = group.users.indexOf(updatedUserOfGroup);

      // @ts-ignore
      const editedGroup: Group = { ...group };
      editedGroup.users[index] = updatedUser;

      await updateGroupAction(editedGroup);

      setLoading(false);
      setMessage({ variant: 'success', text: 'グループを追加しました' });
      if (updatedUser) {
        setRedirect(true);
      }
    } catch (error) {
      setMessage({ variant: 'danger', text: error.message });
      setLoading(false);
    }
  };

  if (redirect) {
    return <Redirect to={`/groups/${group?.id}`} />;
  }

  return (
    <Container>
      <Row className="my-4">
        <Col md="8" className="mx-auto">
          <h2 className="text-center">{id === null ? 'ユーザー招待' : 'ユーザー修正'}</h2>
          { (message.variant !== '' && message.text !== '')
          && <FlashMessage variant={message.variant} text={message.text} /> }
          <Form onSubmit={id === null ? add : update}>
            <FormGroup
              name="名前"
              type="text"
              value={name}
              onChange={setName}
              placeholder="名前"
              text=""
            />
            { id === null
            && (
              <FormGroup
                name="メールアドレス"
                type="text"
                value={email}
                onChange={setEmail}
                placeholder="メールアドレス"
                text="info@vertechc.com"
              />
            )}
            { id !== null
            && (
              <>
                <FormGroup
                  name="企業名"
                  type="text"
                  value={companyName}
                  onChange={setCompanyName}
                  placeholder="企業名"
                  text=""
                />
                <FormGroup
                  name="役職"
                  type="text"
                  value={companyTitle}
                  onChange={setCompanyTitle}
                  placeholder="代表取締役"
                  text=""
                />
                <FormGroup
                  name="住所"
                  type="text"
                  value={companyAddress}
                  onChange={setCompanyAddress}
                  placeholder="住所"
                  text=""
                />
                <FormGroup
                  name="経度"
                  type="text"
                  value={companyLong}
                  onChange={setCompanyLong}
                  placeholder="136.88302861830502"
                  text=""
                />
                <FormGroup
                  name="緯度"
                  type="text"
                  value={companyLat}
                  onChange={setCompanyLat}
                  placeholder="35.162109570206255"
                  text=""
                />
              </>
            )}
            { loading
              ? <Loading message="送信しています..." />
              : (
                <Button className="btn-block" variant="primary" type="submit">
                  {id === null ? '追加' : '修正'}
                </Button>
              )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default UserForm;
