import axios from 'axios';
import { API_PATH } from './config';
import { Board } from '../types/board';

function headers() {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
}

export async function save(board: Board) {
  return axios.post(`${API_PATH}/boards`, board, headers());
}

export async function update(board: Board) {
  return axios.put(`${API_PATH}/boards/${board.id}`, board, headers());
}

export async function destroy(id: number) {
  return axios.delete(`${ API_PATH }/boards/${ id }`, headers());
}

