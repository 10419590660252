import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { FlashMessage, FormGroup, Loading } from '../../components/common';
import { signIn } from '../../redux/operations/session';
import { RootState } from '../../types/store';

const Signin: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const getState = useSelector((state: RootState) => state);

  useEffect(() => {
    if (getState.session.isLoggedIn) {
      dispatch(push('/groups'));
    }
  }, []);

  const login = async (e: any) => {
    e.preventDefault();
    dispatch(signIn(email, password));
  };

  return (
    <Container>
      <Row className="my-4">
        <Col md="8" className="mx-auto">
          <h2 className="text-center">ログイン</h2>
          <Form onSubmit={login}>
            { (getState.session.isError.message !== '')
            && <FlashMessage variant="danger" text={getState.session.isError.message} /> }
            <FormGroup
              name="email"
              type="email"
              value={email}
              onChange={setEmail}
              placeholder="Enter email"
              text="We'll never share your email with anyone else."
            />
            <FormGroup
              name="password"
              type="password"
              value={password}
              onChange={setPassword}
              placeholder="Password"
              text="6文字以上で設定してください"
            />
            { getState.session.isFetching
              ? <Loading message="ログインしています..." />
              : (
                <Button className="btn-block" variant="primary" type="submit">
                  ログイン
                </Button>
              )}
          </Form>
          <Link to="/forget-password">パスワードを忘れましたか？</Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Signin;
