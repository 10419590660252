import React, { useContext } from 'react';
import {
  Col, Container, Row,
  Accordion, Card, useAccordionToggle, AccordionContext,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Linkify from 'react-linkify';

const FAQ: React.FC = (): JSX.Element => {
  const items = [
    {
      id: 0,
      title: 'Q1. 実際にコミュニティ運営をしたい場合にどのような流れになりますか？',
      body: 'A1.トップページの上部の新規登録よりコミュニティ名、担当者名、代表電話番号、登録用メールアドレス、パスワードを入力することで、運営用ページが生成されます。その後、会員の招待等を行ってください。ご不明点は下記URLよりお問い合わせください。また導入時におけるオンラインサポートも無料で行っておりますので、ご希望があればその旨をご記入ください。\n\nお問い合わせフォーム\nhttps://docs.google.com/forms/d/1jj35F_dZLYpno8Lxa54DDiqlKlkbkSFUzcQ0sT_A7eY/edit',
    }, {
      id: 1,
      title: 'Q2. 料金はいくらですか？',
      body: 'A2. 現在はβ版のため全て無料で提供しております。ただし、今後有料化する方針ではありますので、料金等の変更がある場合は事前にご案内させて頂きます。',
    }, {
      id: 2,
      title: 'Q3. 会員の追加または招待の方法は？',
      body: 'A3. 運営アカウント専用ページからのみ招待が可能です。招待したい会員の情報を入力し招待メールを送付します。',
    }, {
      id: 3,
      title: 'Q4. コミュニティ内で退会者が発生した際の会員アカウント削除の方法は？',
      body: 'A4. 運営アカウント専用ページからのみ会員アカウント削除が可能です。',
    }, {
      id: 4,
      title: 'Q5. RAKUKAN解約手続きの方法は？',
      body: 'A5. 下記のお問い合わせフォームより解約希望の旨をお送りください。\nhttps://docs.google.com/forms/d/1jj35F_dZLYpno8Lxa54DDiqlKlkbkSFUzcQ0sT_A7eY/edit',
    },
  ];

  interface Item {
    id: number
    title: string
    body: string
  }

  interface Toggle {
    title: string
    eventKey: string
    callback: any
  }

  const ContextAwareToggle: any = ({ title, eventKey, callback }: Toggle) => {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    if (isCurrentEventKey) {
      return (
        <Card.Header className="w-100 text-left btn-light bg-white text-orange pointer-sec" onClick={decoratedOnClick}>
          {title}
          <div className="float-right d-inline">
            <FontAwesomeIcon icon={faChevronUp} />
          </div>
        </Card.Header>
      );
    }
    return (
      <Card.Header className="w-100 text-left btn-light bg-white text-orange pointer-sec" onClick={decoratedOnClick}>
        {title}
        <div className="float-right d-inline">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </Card.Header>
    );
  };

  return (
    <section id="faq">
      <div className="faq bg-light py-5">
        <Container>
          <h2 className="mb-3 font-weight-bold">
            <span className="h6">よくある質問</span>
            <br />
            FAQ
          </h2>
          <Row>
            {items.map((item:Item) => (
              <Col md="12" className="mx-auto mb-2">
                <Accordion>
                  <Card className="border border-orange">
                    <ContextAwareToggle
                      title={item.title}
                      eventKey={item.id.toString()}
                    />
                    <Accordion.Collapse eventKey={item.id.toString()}>
                      <Card.Body className="body-text">
                        <Linkify>{item.body}</Linkify>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </section>
  );
};
export default FAQ;
