import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  name: string;
  value: string;
  onChange: any;
  placeholder: string;
}

export const TextInput: React.FC<Props> = (props): JSX.Element => {
  const {
    name, value = '', onChange, placeholder = '',
  } = props;
  return (
    <Form.Control
      name={name}
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};
