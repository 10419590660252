import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {
  Col, Container, Row, Table,
} from 'react-bootstrap';
import { Group } from '../../types/group';
import { findAll } from '../../redux/operations/groups';
import { RootState } from '../../types/store';

const GroupIndex: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const getState = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(findAll());
  }, []);

  return (
    <Container>
      <Row className="my-4">
        <Col md="8" className="mx-auto">
          <div className="d-flex my-3">
            <h2>グループ一覧</h2>
          </div>
          { getState.groups.isFetching
            ? <Skeleton />
            : (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>グループ名</th>
                    <th>詳細</th>
                  </tr>
                </thead>
                <tbody>
                  { getState.groups.data.map((group: Group) => (
                    <tr key={group.id}>
                      <td>{ group.id }</td>
                      <td>{ group.name }</td>
                      <td><Link to={`/groups/${group.id}`}>詳細</Link></td>
                    </tr>
                  )) }
                </tbody>
              </Table>
            )}
        </Col>
      </Row>
    </Container>
  );
};

export default GroupIndex;
