import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import { FlashMessage, Loading } from '../../components/common';
import { update as groupUpdate } from '../../redux/operations/groups';
import { Group } from '../../types/group';
import { RootState } from '../../types/store';
import { FormLabel } from '../../components/forms/FormLabel';
import { TextInput } from '../../components/forms/TextInput';
import { TextAreaInput } from '../../components/forms/TextAreaInput';

const GroupForm: React.FC = (props: any): JSX.Element => {
  const [group, setGroup] = useState<Group>({} as Group);

  const [loading, setLoading] = useState(false);
  const { data } = useSelector((state: any) => state.groups);
  const getState = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.id !== 'new') {
      setGroup(data.find((g: Group) => g.id === Number(props.match.params.id)));
    }
  }, []);

  const update = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    dispatch(groupUpdate(group));
  };

  return (
    <Container>
      <Row className="my-4">
        <Col md="8" className="mx-auto">
          <h2 className="text-center">グループ作成</h2>
          { (getState.groups.isError.message !== '')
          && <FlashMessage variant="danger" text={getState.groups.isError.message} /> }
          <Form onSubmit={update}>
            <FormLabel name="グループ名" />
            <TextInput
              name="グループ名"
              value={group.name}
              onChange={(e: any) => setGroup({
                id: group.id,
                name: e.target.value,
                description: group.description,
                is_owner: group.is_owner,
                users: group.users,
                boards: group.boards,
                calendars: group.calendars,
              } as Group)}
              placeholder="グループ名"
            />
            <FormLabel name="詳細" />
            <TextAreaInput
              name="内容"
              value={group.description}
              onChange={(e: any) => setGroup({
                id: group.id,
                name: group.name,
                description: e.target.value,
                is_owner: group.is_owner,
                users: group.users,
                boards: group.boards,
                calendars: group.calendars,
              } as Group)}
              placeholder="グループの詳細を記入してください"
            />
            { loading
              ? <Loading message="送信しています..." />
              : (
                <Button className="btn-block mt-5" variant="primary" type="submit">
                  修正
                </Button>
              )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default GroupForm;
