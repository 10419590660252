import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const PrivacyPolicy: React.FC = (): JSX.Element => (
  <Container>
    <Row className="my-4">
      <Col md="8" className="mx-auto">
        <h2 className="text-center">プライバシーポリシー</h2>

        Vertech Consulting株式会社（以下，「当社」といいます。）は、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）における、
        ユーザーの個⼈情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。

        <h3>第 1 条（個⼈情報）</h3>
        「個⼈情報」とは、個⼈情報保護法にいう「個⼈情報」を指すものとし、⽣存する個⼈に関する情報であって、
        当該情報に含まれる⽒名、⽣年⽉⽇、住所、電話番号、連絡先その他の記述等により特定の個⼈を識別できる情報及び容貌、指紋、声紋にかかるデータ、
        及び健康保険証の保険者番号などの当該情報単体から特定の個⼈を識別できる情報（個⼈識別情報）を指します。

        <h3>第 2 条（個⼈情報の収集⽅法）</h3>
        当社は、ユーザーが利⽤登録をする際に⽒名、⽣年⽉⽇、住所、電話番号、メールアドレス、銀⾏⼝座番号、クレジットカード番号、
        運転免許証番号などの個⼈情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個⼈情報を含む取引記録や決済に関する情報を、
        当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。） などから収集することがあります。

        <h3>第 3 条（個⼈情報を収集・利⽤する⽬的）</h3>
        当社が個⼈情報を収集・利⽤する⽬的は、以下のとおりです。
        当社サービスの提供・運営のため
        2. ユーザーからのお問い合わせに回答するため（本⼈確認を⾏うことを含む）
        3. ユーザーが利⽤中のサービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため
        4. メンテナンス、重要なお知らせなど必要に応じたご連絡のため
        5. 利⽤規約に違反したユーザーや、不正・不当な⽬的でサービスを利⽤しようとするユーザーの特定をし、ご利⽤をお断りするため
        6. ユーザーにご⾃⾝の登録情報の閲覧や変更、削除、ご利⽤状況の閲覧を行っていただくため
        7. 有料サービスにおいて、ユーザーに利⽤料⾦を請求するため
        8. 上記の利⽤⽬的に付随する⽬的

        <h3>第 4 条（利⽤⽬的の変更）</h3>
        当社は、利⽤⽬的が変更前と関連性を有すると合理的に認められる場合に限り、個⼈情報の利⽤⽬的を変更するものとします。
        利⽤⽬的の変更を行った場合には、変更後の⽬的について、当社所定の⽅法により、ユーザーに通知し、または本ウェブサイト上に公表するものとします。

        <h3>第 5 条（個⼈情報の第三者提供）</h3>
        当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個⼈情報を提供することはありません。
        ただし，個⼈情報保護法その他の法令で認められる場合を除きます。
        ⼈の⽣命、⾝体または財産の保護のために必要がある場合であって、本⼈の同意を得ることが困難であるとき
        公衆衛⽣の向上または児童の健全な育成の推進のために特に必要がある場合であって、本⼈の同意を得ることが困難であるとき
        国の機関もしくは地⽅公共団体またはその委託を受けた者が法令の定める事務を遂⾏することに対して協⼒する必要がある場合であって、
        本⼈の同意を得ることにより当該事務の遂⾏に⽀障を及ぼすおそれがあるとき
        予め次の事項を告知あるいは公表し、かつ当社が個⼈情報保護委員会に届出をしたとき
        1. 利⽤⽬的に第三者への提供を含むこと
        2. 第三者に提供されるデータの項⽬
        3. 第三者への提供の⼿段または⽅法
        4. 本⼈の求めに応じて個⼈情報の第三者への提供を停⽌すること
        5. 本⼈の求めを受け付ける⽅法
        2. 前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
        当社が利⽤⽬的の達成に必要な範囲内において個⼈情報の取扱いの全部または⼀部を委託する場合
        合併その他の事由による事業の承継に伴って個⼈情報が提供される場合
        個⼈情報を特定の者との間で共同して利⽤する場合であって、その旨並びに共同して利⽤される個⼈情報の項⽬、共同して利⽤する者の範囲、
        利⽤する者の利⽤⽬的および当該個⼈情報の管理について責任を有する者の⽒名または名称について、あらかじめ本⼈に通知し、または本⼈が容易に知り得る状態に置いた場合

        <h3>第 6 条（個⼈情報の開⽰）</h3>
        当社は、本⼈から個⼈情報の開⽰を求められたときは、本⼈に対し、遅滞なくこれを開⽰します。
        ただし、開⽰することにより次のいずれかに該当する場合は、その全部または⼀部を開⽰しないこともあり、開⽰しない決定をした場合には、
        その旨を遅滞なく通知します。なお，個⼈情報の開⽰に際しては、1 件あたり 1,000円の⼿数料を申し受けます。
        本⼈または第三者の⽣命、⾝体、財産その他の権利利益を害するおそれがある場合
        当社の業務の適正な実施に著しい⽀障を及ぼすおそれがある場合
        その他法令に違反することとなる場合
        その他、当社が本サービスの提供が困難と判断した場合
        前項の定めにかかわらず、履歴情報および特性情報などの個⼈情報以外の情報については、原則として開⽰いたしません。

        <h3>第 7 条（個⼈情報の訂正および削除）</h3>
        ユーザーは、当社の保有する⾃⼰の個⼈情報が誤った情報である場合には、当社が定める⼿続きにより、
        当社に対して個⼈情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。
        当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個⼈情報の訂正等を⾏うものとします。
        当社は、前項の規定に基づき訂正等を⾏った場合、または訂正等を⾏わない旨の決定をしたときは遅滞なく、これをユーザーに通知します。

        <h3>第 8 条（個⼈情報の利⽤停⽌等）</h3>
        当社は、本⼈から、個⼈情報が、利⽤⽬的の範囲を超えて取り扱われているという理由、
        または不正の⼿段により取得されたものであるという理由により、その利⽤の停⽌または消去（以下、「利⽤停⽌等」といいます。）
        を求められた場合には、遅滞なく必要な調査を⾏います。
        2. 前項の調査結果に基づき、その請求に応じる必要があると判断した場合に
        は、遅滞なく、当該個⼈情報の利⽤停⽌等を⾏います。
        当社は、前項の規定に基づき利⽤停⽌等を⾏った場合、または利⽤停⽌等を⾏わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。
        前2項にかかわらず、利⽤停⽌等に多額の費⽤を有する場合その他利⽤停⽌等を⾏うことが困難な場合であって、
        ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。

        <h3>第 9 条（プライバシーポリシーの変更）</h3>
        1.本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。
        2.当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効⼒を⽣じるものとします。

        <h3>第 10 条（お問い合わせ窓⼝）</h3>
        本ポリシーに関するお問い合わせは、下記の窓⼝までお願いいたします。

        住所： 愛知県名古屋市中村区平池町4丁目60番地の12 グローバルゲート11階
        社名： Vertech Consulting株式会社
        代表者：大久保将広
        E メールアドレス：info@vertechc.com
      </Col>
    </Row>
  </Container>
);

export default PrivacyPolicy;
