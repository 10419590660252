import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { Board } from '../../../types/board';
import { Group } from '../../../types/group';

const BoardDetail: React.FC = (props: any): JSX.Element => {
  const { data } = useSelector((state: any) => state.groups);
  const [board, setBoard] = useState<Board>({} as Board);

  useEffect(() => {
    if (props.match.params.groupId && props.match.params.id) {
      const newBoard = data.find((g: Group) => g.id === Number(props.match.params.groupId))
        .boards?.find((b: Board) => b.id === Number(props.match.params.id));
      setBoard(newBoard);
    }
  }, []);

  return (
    <Container>
      <Row className="my-4">
        <Col md="8" className="mx-auto">
          <div className="d-flex my-3">
            <h2>{`${board?.title}の詳細`}</h2>
            <Button className="ml-4">
              <Link to={`/groups/${board?.group_id}/boards/${board?.id}/form`} className="text-white">修正</Link>
            </Button>
          </div>
          <p>{ board?.body }</p>
        </Col>
      </Row>
    </Container>
  );
};

export default BoardDetail;
