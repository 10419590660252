import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import colImage1 from '../../images/invite_mail.png';
import colImage2 from '../../images/profile_edit.png';
import colImage3 from '../../images/mapping_details.png';

const HowToUse: React.FC = (): JSX.Element => (
  <section id="how-to-use">
    <div className="how-to-use">
      <Container>
        <h2 className="mb-3 font-weight-bold">HOW TO USE</h2>
        <Row>
          <Col md="4" className="mb-3">
            <div className="card h-100 border-0">
              <img src={colImage1} alt="colImage1" className="card-img-top rounded" />
              <div className="card-body">
                <div className="card-number">01</div>
                <div className="position-relative">
                  <div className="box-absolute">
                    <div className="card-absolute-body">
                      <h3 className="font-weight-bold">運営アカウントの登録</h3>
                      <p>
                        コミュニティの運営アカウントと専用ページを作成し、会員を招待します。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="4" className="mb-3">
            <div className="card h-100 border-0">
              <img src={colImage2} alt="colImage2" className="card-img-top rounded" />
              <div className="card-body">
                <div className="card-number">02</div>
                <div className="position-relative">
                  <div className="box-absolute">
                    <div className="card-absolute-body">
                      <h3 className="font-weight-bold">会員招待/登録</h3>
                      <p>
                        運営側より届く招待メールを受け取り、アプリをインストールし、プロフィールページを完成させます。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="4" className="mb-3">
            <div className="card h-100 border-0">
              <img src={colImage3} alt="colImage3" className="card-img-top rounded" />
              <div className="card-body">
                <div className="card-number">03</div>
                <div className="position-relative">
                  <div className="box-absolute">
                    <div className="card-absolute-body">
                      <h3 className="font-weight-bold">マップ上で繋がろう</h3>
                      <p>
                        現在地や条件等で検索をし、繋がりたい会員と繋がることができます。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
);

export default HowToUse;
