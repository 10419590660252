import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import {
  addSession, deleteSession, fetchSession, fetchingFinishedSession, failSession,
} from '../actions/session';
import {
  login as firebaseLogin, logout as firebaseLogout,
} from '../../services/firebase';
import auth from '../../services/users';

export const signIn = (email: string, password: string) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(fetchSession());
  if (email === '' || password === '') {
    dispatch(failSession({ status: '', message: 'Emailとパスワードを入力してください' }));
    return;
  }
  try {
    const token: any = await firebaseLogin(email, password);
    localStorage.setItem('token', token?.user?.za);
    const user = await auth.me();
    dispatch(addSession(user.data));
    dispatch(fetchingFinishedSession());
    dispatch(push('/groups'));
  } catch (e) {
    dispatch(fetchingFinishedSession());
    dispatch(failSession({ status: e.code, message: e.message }));
  }
};

export const logout = () => async (dispatch: Dispatch): Promise<void> => {
  await firebaseLogout();
  dispatch(deleteSession());
  dispatch(push('/login'));
};
