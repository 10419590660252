import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { Group } from '../../../types/group';
import { User } from '../../../types/user';

const UserDetail: React.FC = (props: any): JSX.Element => {
  const { data } = useSelector((state: any) => state.groups);
  const [group, setGroup] = useState<Group>({} as Group);
  const [user, setUser] = useState<User>({} as User);

  useEffect(() => {
    if (props.match.params.groupId && props.match.params.id) {
      const newGroup = data.find((g: Group) => g.id === Number(props.match.params.groupId));
      setGroup(newGroup);
      const newUser = newGroup.users?.find((u: User) => u.id === Number(props.match.params.id));
      setUser(newUser);
    }
  }, []);

  return (
    <Container>
      <Row className="my-4">
        <Col md="8" className="mx-auto">
          <div className="d-flex my-3">
            <h2>{`${user?.name}の詳細`}</h2>
            <Button className="ml-4">
              <Link to={`/groups/${group.id}/users/${user?.id}/form`} className="text-white">修正</Link>
            </Button>
          </div>
          <p>
            <span>名前：</span>
            { user?.name }
          </p>
          <p>
            <span>企業名前：</span>
            { user?.company?.name }
          </p>
          <p>
            <span>役職：</span>
            { user?.company?.title }
          </p>
          <p>
            <span>住所：</span>
            { user?.company?.address }
          </p>
          <p>
            <span>long：</span>
            { user?.company?.long }
          </p>
          <p>
            <span>lat：</span>
            { user?.company?.lat }
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default UserDetail;
