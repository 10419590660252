import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { Group } from '../../types/group';
import {
  failGroup, fetchingFinishedGroup, updateGroup,
} from '../actions/groups';
import {
  save as boardSave, update as boardUpdate, destroy as boardDestroy,
} from '../../services/boards';
import { Board } from '../../types/board';
import { RootState } from '../../types/store';

export const save = (board: Board) => async (
  dispatch: Dispatch, getState: RootState,
): Promise<void> => {
  dispatch(fetchingFinishedGroup());
  const { groups } = getState();

  if (board.title === '' || board.body === '') {
    dispatch(failGroup({ message: 'タイトルと内容を入力してください！', status: '' }));
  }
  try {
    const group = groups.data.find((g: Group) => g.id === Number(board.group_id));
    const response = await boardSave(board);
    const createdBoard = response.data as Board;
    const newGroup: Group = { ...group };
    newGroup.boards.push(createdBoard);
    dispatch(updateGroup(newGroup));

    dispatch(fetchingFinishedGroup());
    dispatch(push(`/groups/${group.id}/boards/${createdBoard.id}`));
  } catch (error) {
    dispatch(failGroup({ message: error.message, status: '' }));
  }
};

export const update = (board: Board) => async (
  dispatch: Dispatch, getState: RootState,
): Promise<void> => {
  dispatch(fetchingFinishedGroup());
  const { groups } = getState();

  if (board.title === '' || board.body === '') {
    dispatch(failGroup({ message: 'タイトルと内容を入力してください！', status: '' }));
  }
  try {
    const group = groups.data.find((g: Group) => g.id === Number(board.group_id));
    const response = await boardUpdate(board);
    const updatedBoard = response.data as Board;
    const updatedBoardOfGroup = group?.boards.find(
      (b: Board) => b.id === Number(updatedBoard.id),
    );
    const index = group.boards.indexOf(updatedBoardOfGroup);
    const editedGroup: Group = { ...group };
    editedGroup.boards[index] = updatedBoard;
    dispatch(updateGroup(editedGroup));

    dispatch(fetchingFinishedGroup());
    dispatch(push(`/groups/${group.id}/boards/${updatedBoard.id}`));
  } catch (error) {
    dispatch(failGroup({ message: error.message, status: '' }));
  }
};

export const destroy = (board: Board) => async (
  dispatch: Dispatch, getState: RootState,
): Promise<void> => {
  const { groups } = getState();
  await boardDestroy(board.id);
  const group = groups.data.find((g: Group) => g.id === Number(board.group_id));
  const newGroup: Group = { ...group };
  const targetBoard: Board = newGroup
    .boards.filter((b: Board) => b.id === board.id)[0];
  const index = newGroup.boards.indexOf(targetBoard);
  newGroup.boards.splice(index, 1);
  dispatch(updateGroup(newGroup));
};
