import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import { Calendar } from '../types/calendar';
import { Board } from '../types/board';
import { User } from '../types/session';

interface Props {
  data: Array<User|Board|Calendar>;
  columns: string[];
  baseLink: string;
  editable: boolean;
  deleteFunc?: any;
}

export const CustomTable: React.FC<Props> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const {
    data, columns, baseLink, editable, deleteFunc,
  } = props;

  const handleDeleteCalendar = async (func: any, item: User|Board|Calendar) => {
    confirmAlert({
      title: '削除の確認',
      message: 'この操作は取り消せませんがよろしいですか？',
      buttons: [
        {
          label: '削除',
          onClick: () => dispatch(func(item)),
        },
        {
          label: 'キャンセル',
          onClick: () => {},
        },
      ],
    });
  };
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          { columns.map((column: string) => (
            <th>{column}</th>
          )) }
          <th>詳細</th>
          { editable ? <th>編集</th> : null }
          { deleteFunc ? <th>削除</th> : null }
        </tr>
      </thead>
      <tbody>
        { data?.map((item) => (
          <tr key={item.id}>
            { columns.map((column: string) => (
              // @ts-ignore
              <th>{ item[column] }</th>
            )) }
            <td>
              <Link to={`${baseLink}/${item.id}`}>詳細</Link>
            </td>
            { editable
              ? (
                <td>
                  <Link to={`${baseLink}/${item.id}/form`}>編集</Link>
                </td>
              ) : null }
            { deleteFunc
              ? (
                <td>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => handleDeleteCalendar(deleteFunc, item)}
                  >
                    削除
                  </div>
                </td>
              ) : null }
          </tr>
        )) }
      </tbody>
    </Table>
  );
};
