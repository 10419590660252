import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Navbar } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import logo from '../images/rakukan_logo.png';
import { logout } from '../redux/operations/session';
import { RootState } from '../types/store';

const Nav: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const getState = useSelector((state: RootState) => state);

  return (
    <Navbar expand="lg" className="navbar navbar-expand-lg navbar-light bg-white border-bottom py-0">
      <Navbar.Brand>
        <div className="navbar-brand pb-2">
          <Link to="/">
            <img src={logo} alt="rakukan logo" height="50px" width="200px" />
          </Link>
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {/* eslint-disable-next-line no-prototype-builtins */}
        { getState.session.isLoggedIn
          ? (
            <div className="ml-auto navbar-nav">
              <div className="nav-link">
                <Link to="/groups" className="text-dark">グループ一覧へ</Link>
              </div>
              <div className="nav-link">
                <Button onClick={() => dispatch(logout())}>LogOut</Button>
              </div>
            </div>
          )
          : (
            <>
              <div className="mr-auto navbar-nav">
                <div className="nav-link">
                  <AnchorLink className="text-dark" href="#features">サービスの特徴</AnchorLink>
                </div>
                <div className="nav-link">
                  <AnchorLink className="text-dark" href="#how-to-use">使い方</AnchorLink>
                </div>
                <div className="nav-link">
                  <AnchorLink className="text-dark" href="#faq">よくある質問</AnchorLink>
                </div>
              </div>
              <div className="ml-auto navbar-nav">
                <div className="nav-link">
                  <Link className="text-dark" to="/login">ログイン</Link>
                </div>
              </div>
            </>
          )}
      </Navbar.Collapse>
    </Navbar>

  );
};

export default Nav;
