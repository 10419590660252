import { ErrorStatus } from './error_status';
import { Company } from './company';

export const GET_SESSION = 'GET_SESSION';
export const ADD_SESSION = 'ADD_SESSION';
export const DELETE_SESSION = 'DELETE_SESSION';
export const FETCHING_SESSION = 'FETCHING_SESSION';
export const FETCHING_FINISHED_SESSION = 'FETCHING_FINISHED_SESSION';
export const FAIL_SESSION_REQUEST = 'FAIL_SESSION_REQUEST';

export interface User {
  id: number;
  name: string;
  email?: string;
  password?: string;
  company: Company;
}

export interface SessionState {
  user: User;
  isLoggedIn?: boolean;
  isFetching?: boolean;
  isError: ErrorStatus
}

export interface GetSessionAction {
  type: typeof GET_SESSION;
  payload: {};
}

export interface AddSessionAction {
  type: typeof ADD_SESSION;
  payload: { user: User };
}

export interface DeleteSessionAction {
  type: typeof DELETE_SESSION;
  payload: {};
}

export interface FetchingSessionAction {
  type: typeof FETCHING_SESSION;
  payload: {};
}

export interface FetchingFinishedSessionAction {
  type: typeof FETCHING_FINISHED_SESSION;
  payload: {};
}

export interface FailSessionRequestAction {
  type: typeof FAIL_SESSION_REQUEST;
  payload: { error: ErrorStatus }
}

export type SessionActionTypes =
  GetSessionAction |
  AddSessionAction |
  DeleteSessionAction |
  FetchingSessionAction |
  FetchingFinishedSessionAction |
  FailSessionRequestAction;
