import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import { FlashMessage, Loading } from '../../../components/common';
import { save as boardSave, update as boardUpdate } from '../../../redux/operations/boards';
import { Board } from '../../../types/board';
import { Group } from '../../../types/group';
import { RootState } from '../../../types/store';
import { TextInput } from '../../../components/forms/TextInput';
import { FormLabel } from '../../../components/forms/FormLabel';
import { TextAreaInput } from '../../../components/forms/TextAreaInput';

const BoardForm: React.FC = (props: any): JSX.Element => {
  const [group, setGroup] = useState({} as Group);
  const [board, setBoard] = useState<Board>({} as Board);
  const [loading, setLoading] = useState<boolean>(false);
  const getState = useSelector((state: RootState) => state);
  const { data } = useSelector((state: RootState) => state.groups);
  const { user } = useSelector((state: RootState) => state.session);

  const dispatch = useDispatch();

  useEffect(() => {
    const newGroup = data.find((g: Group) => g.id === Number(props.match.params.groupId));
    setGroup(newGroup);
    if (props.match.params.id !== 'new') {
      const newBoard = newGroup.boards?.find((b: Board) => b.id === Number(props.match.params.id));
      setBoard(newBoard);
    }
  }, []);

  const add = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    dispatch(boardSave(board));
  };

  const update = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    dispatch(boardUpdate(board));
  };

  return (
    <Container>
      <Row className="my-4">
        <Col md="8" className="mx-auto">
          <h2 className="text-center">{board.id === undefined ? 'タイムライン追加' : 'タイムライン修正'}</h2>
          { (getState.groups.isError.message !== '')
          && <FlashMessage variant="danger" text={getState.groups.isError.message} /> }
          <Form onSubmit={board.id === undefined ? add : update}>
            <FormLabel name="タイトル" />
            <TextInput
              name="タイトル"
              value={board.title}
              onChange={(e: any) => setBoard({
                id: board.id,
                title: e.target.value,
                body: board.body,
                group_id: group.id,
                user_id: user.id,
              } as Board)}
              placeholder="タイトル"
            />
            <FormLabel name="内容" />
            <TextAreaInput
              name="内容"
              value={board.body}
              onChange={(e: any) => setBoard({
                id: board.id,
                title: board.title,
                body: e.target.value,
                group_id: group.id,
                user_id: user.id,
              } as Board)}
              placeholder="タイムラインの詳細を記入してください"
            />
            { loading
              ? <Loading message="送信しています..." />
              : (
                <Button className="btn-block mt-5" variant="primary" type="submit">
                  {board.id === undefined ? '追加' : '修正'}
                </Button>
              )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default BoardForm;
