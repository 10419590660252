import axios from 'axios';
import { API_PATH } from './config';
import { Calendar } from '../types/calendar';

function headers() {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
}

export async function save(calendar: Calendar) {
  return axios.post(`${API_PATH}/calendars`, calendar, headers());
}

export async function update(calendar: Calendar) {
  return axios.put(`${API_PATH}/calendars/${calendar.id}`, calendar, headers());
}

export async function destroy(id: number) {
  return axios.delete(`${API_PATH}/calendars/${id}`, headers());
}
