import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute: React.FC<any> = ({
  component: Component, isAuthenticated, ...rest
}: any): JSX.Element => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthenticated) {
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      );
    }}
  />
);
export default PrivateRoute;
