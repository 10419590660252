import React from 'react';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from './utils/history';
import Routes from './navigation/routes';
import Header from './components/Header';

import Store from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer';
import './App.scss';

const App: React.FC = () => (
  <Provider store={Store}>
    <ConnectedRouter history={history}>
      <Header />
      <Routes />
    </ConnectedRouter>
    <Footer />
  </Provider>
);

export default App;
