import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import Reducers from '../reducers';
import history from '../../utils/history';

// const middleware = applyMiddleware(thunk);

// 毎回localStorageに保存
function saveToLocalStorage(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

// リフレッシュしたらlocalStorageから取得
function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return;
    }
    const json: any = JSON.parse(serializedState);
    json.session.isError = { status: '', message: '' };
    json.session.isFetching = false;
    json.groups.isError = { status: '', message: '' };
    json.groups.isFetching = false;
    // json.notification = { message: '', type: '' };
    // eslint-disable-next-line consistent-return
    return json;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

const persistedState = loadFromLocalStorage();

const store = createStore(
  Reducers(history),
  persistedState,
  compose(
    applyMiddleware(
      routerMiddleware(history),
      thunk,
    ),
  ),
  // middleware,
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
