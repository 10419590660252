import * as types from '../../types/session';

const initialState: types.SessionState = {
  user: {} as types.User,
  isLoggedIn: false,
  isFetching: false,
  isError: {
    status: '',
    message: '',
  },
};

export default function sessionReducer(
  state = initialState, action: types.SessionActionTypes,
): types.SessionState {
  switch (action.type) {
    case types.GET_SESSION:
      return { ...state };
    case types.ADD_SESSION: {
      const { user } = action.payload;
      return {
        ...state,
        user,
        isLoggedIn: true,
      };
    }
    case types.DELETE_SESSION:
      return {
        ...state,
        user: {} as types.User,
        isLoggedIn: false,
      };
    case types.FETCHING_SESSION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case types.FETCHING_FINISHED_SESSION: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case types.FAIL_SESSION_REQUEST: {
      const { error } = action.payload;
      return {
        ...state,
        isError: { status: error.status, message: error.message },
      };
    }
    default:
      return state;
  }
}
