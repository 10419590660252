import * as types from '../../types/session';
import { ErrorStatus } from '../../types/error_status';
import { User } from '../../types/user';

export function addSession(user: User): types.SessionActionTypes {
  return {
    type: types.ADD_SESSION,
    payload: { user },
  };
}

export function getSession(): types.SessionActionTypes {
  return {
    type: types.GET_SESSION,
    payload: {},
  };
}

export function deleteSession(): types.SessionActionTypes {
  return {
    type: types.DELETE_SESSION,
    payload: {},
  };
}

export function fetchSession(): types.SessionActionTypes {
  return {
    type: types.FETCHING_SESSION,
    payload: {},
  };
}

export function fetchingFinishedSession(): types.SessionActionTypes {
  return {
    type: types.FETCHING_FINISHED_SESSION,
    payload: {},
  };
}

export function failSession(error: ErrorStatus): types.SessionActionTypes {
  return {
    type: types.FAIL_SESSION_REQUEST,
    payload: { error },
  };
}
