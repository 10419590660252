import * as types from '../../types/group';
import { ErrorStatus } from '../../types/error_status';

export function getGroups(): types.GroupActionTypes {
  return {
    type: types.GET_GROUPS,
    payload: {},
  };
}

export function addGroups(data: types.Group[]): types.GroupActionTypes {
  return {
    type: types.ADD_GROUPS,
    payload: { data },
  };
}

export function updateGroup(data: types.Group): types.GroupActionTypes {
  return {
    type: types.UPDATE_GROUP,
    payload: { data },
  };
}

export function deleteGroup(id: number): types.GroupActionTypes {
  return {
    type: types.DELETE_GROUP,
    payload: { id },
  };
}

export function fetchingGroup(): types.GroupActionTypes {
  return {
    type: types.FETCHING_GROUP,
    payload: {},
  };
}

export function fetchingFinishedGroup(): types.GroupActionTypes {
  return {
    type: types.FETCHING_FINISHED_GROUP,
    payload: {},
  };
}

export function failGroup(error: ErrorStatus): types.GroupActionTypes {
  return {
    type: types.FAIL_GROUP_REQUEST,
    payload: { error },
  };
}
