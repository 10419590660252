import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import colImage1 from '../../images/feature_map.jpg';
import colImage2 from '../../images/feature_schedule.jpg';
import colImage3 from '../../images/feature_management.jpg';

const Features: React.FC = (): JSX.Element => (
  <section id="features">
    <div className="features">
      <Container>
        <div className="text-center mt-3">
          <h2 className="mb-3 font-weight-bold">
            サービスの特徴
            <br />
            <span className="h4 text-orange">FEATURES</span>
          </h2>
        </div>
        <Row>
          <Col md="4" className="mb-3">
            <div className="card h-100 border-0">
              <img src={colImage1} alt="colImage1" className="card-img-top rounded" />
              <div className="card-body">
                <h3 className="font-weight-bold">マップ上で近くの会員を探せる！</h3>
                <p>
                  現在地付近の会員情報が一目でわかるため、お店探しや仕事の依頼、1to1ミーティングなど、会員同士がスムーズに繋がれます。
                </p>
              </div>
            </div>
          </Col>
          <Col md="4" className="mb-3">
            <div className="card h-100 border-0">
              <img src={colImage2} alt="colImage2" className="card-img-top rounded" />
              <div className="card-body">
                <h3 className="font-weight-bold">カレンダー機能で予定を把握！</h3>
                <p>
                  カレンダーでコミュニティ内の予定を確認できるので、予定の管理がスムーズになります。
                </p>
              </div>
            </div>
          </Col>
          <Col md="4" className="mb-3">
            <div className="card h-100 border-0">
              <img src={colImage3} alt="colImage3" className="card-img-top rounded" />
              <div className="card-body">
                <h3 className="font-weight-bold">タイムラインで最新の予定を確認！</h3>
                <p>
                  カレンダー機能とタイムラインが連動することで、最新の予定を一目で確認できます。さらに通知機能もあるので予定の見落としを防ぐことができます。
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
);

export default Features;
