import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { Group } from '../../../types/group';

import { Calendar } from '../../../types/calendar';
import { formatDate } from '../../../components/common';

const CalendarDetail: React.FC = (props: any): JSX.Element => {
  const { data } = useSelector((state: any) => state.groups);
  const [calendar, setCalendar] = useState<Calendar>({} as Calendar);

  useEffect(() => {
    if (props.match.params.groupId && props.match.params.id) {
      const newCalendar = data.find((g: Group) => g.id === Number(props.match.params.groupId))
        .calendars?.find((c: Calendar) => c.id === Number(props.match.params.id));
      setCalendar(newCalendar);
    }
  }, []);

  return (
    <Container>
      <Row className="my-4">
        <Col md="8" className="mx-auto">
          <div className="d-flex my-3">
            <h2>{`${calendar?.title}の詳細`}</h2>
            <Button className="ml-4">
              <Link to={`/groups/${calendar?.group_id}/calendars/${calendar?.id}/form`} className="text-white">修正</Link>
            </Button>
          </div>
          <p>
            <span>詳細：</span>
            { calendar?.description }
          </p>
          <p>
            <span>url：</span>
            { calendar?.url }
          </p>
          <p>
            <span>開始時間：</span>
            { calendar?.start_at && formatDate(calendar?.start_at) }
          </p>
          <p>
            <span>終了時間：</span>
            { calendar?.end_at && formatDate(calendar?.end_at) }
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default CalendarDetail;
