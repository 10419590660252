import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { Group } from '../../types/group';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { RootState } from '../../types/store';
import { CustomTable } from '../../components/CustomTable'; // Import css
import { destroy as calendarDestroy } from '../../redux/operations/calendars';
import { destroy as boardDestroy } from '../../redux/operations/boards';

const GroupDetail: React.FC = (props: any): JSX.Element => {
  const getState = useSelector((state: RootState) => state);
  const [group, setGroup] = useState({} as Group);

  useEffect(() => {
    setGroup(getState.groups.data.find((g: Group) => g.id === Number(props.match.params.id)));
  }, []);

  return (
    <Container>
      <Row className="my-4">
        <Col md="8" className="mx-auto">
          <div className="d-flex my-3">
            <h2>{`${group?.name}の詳細`}</h2>

            <Button className="ml-4">
              <Link to={`/groups/${group.id}/form`} className="text-white">修正</Link>
            </Button>
          </div>
          <p>{ group?.description }</p>

          <div className="d-flex my-3">
            <h4>招待ユーザー一覧</h4>
            <Button className="ml-4">
              <Link to={`/groups/${group.id}/users/new/form`} className="text-white">招待</Link>
            </Button>
          </div>
          <CustomTable
            data={group.users}
            columns={['id', 'name']}
            baseLink={`/groups/${group.id}/users`}
            editable
          />

          <div className="d-flex my-3">
            <h4>タイムライン一覧</h4>
            <Button className="ml-4">
              <Link to={`/groups/${group.id}/boards/new/form`} className="text-white">追加</Link>
            </Button>
          </div>

          <CustomTable
            data={group?.boards}
            columns={['id', 'title']}
            baseLink={`/groups/${group.id}/boards`}
            editable
            deleteFunc={boardDestroy}
          />

          <div className="d-flex my-3">
            <h4>カレンダー一覧</h4>
            <Button className="ml-4">
              <Link to={`/groups/${group.id}/calendars/new/form`} className="text-white">追加</Link>
            </Button>
          </div>

          <CustomTable
            data={group?.calendars}
            columns={['id', 'title']}
            baseLink={`/groups/${group.id}/calendars`}
            editable
            deleteFunc={calendarDestroy}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default GroupDetail;
