import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { getUserState } from '../services/firebase';
// import the components
import { RootState } from '../types/store';
import Home from '../pages/Home';
import Login from '../pages/auth/Login';
import NotFound from '../pages/NotFound';
import PrivateRoute from './PrivateRoute';
import GroupForm from '../pages/groups/GroupForm';
import GroupDetail from '../pages/groups/GroupDetail';
import UserForm from '../pages/groups/users/UserForm';
import Tos from '../pages/common/Tos';
import PrivacyPolicy from '../pages/common/PrivacyPolicy';
import GroupIndex from '../pages/groups/GroupIndex';
import BoardForm from '../pages/groups/boards/BoardForm';
import BoardDetail from '../pages/groups/boards/BoardDetail';
import UserDetail from '../pages/groups/users/UserDetail';
import CalendarDetail from '../pages/groups/calendars/CalendarDetail';
import CalendarForm from '../pages/groups/calendars/CalendarForm';

const Routes: React.FC = (): JSX.Element => {
  const getState = useSelector((s: RootState) => s);
  const { session } = getState;
  console.log(session);
  // firebaseのログイン状態を常に更新するため
  // getUserState();

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      { /* Auth関連 */ }
      <Route exact path="/login" component={Login} />

      <Route exact path="/tos" component={Tos} />
      <Route exact path="/privacy" component={PrivacyPolicy} />

      <PrivateRoute exact path="/groups" component={GroupIndex} isAuthenticated={session.isLoggedIn} />
      { /* Group作成関連 */ }
      <PrivateRoute exact path="/groups/:id" component={GroupDetail} isAuthenticated={session.isLoggedIn} />
      <PrivateRoute exact path="/groups/:id/form" component={GroupForm} isAuthenticated={session.isLoggedIn} />
      {/* <PrivateRoute exact path="/groups/:groupId/users/:id/form" component={UserForm} /> */}

      <PrivateRoute exact path="/groups/:groupId/boards/:id" component={BoardDetail} isAuthenticated={session.isLoggedIn} />
      <PrivateRoute exact path="/groups/:groupId/boards/:id/form" component={BoardForm} isAuthenticated={session.isLoggedIn} />

      <PrivateRoute exact path="/groups/:groupId/calendars/:id" component={CalendarDetail} isAuthenticated={session.isLoggedIn} />
      <PrivateRoute exact path="/groups/:groupId/calendars/:id/form" component={CalendarForm} isAuthenticated={session.isLoggedIn} />

      <PrivateRoute exact path="/groups/:groupId/users/:id" component={UserDetail} isAuthenticated={session.isLoggedIn} />
      <PrivateRoute exact path="/groups/:groupId/users/:id/form" component={UserForm} isAuthenticated={session.isLoggedIn} />

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
