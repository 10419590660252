import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import {
  findAll as groupFindAll, findOne as groupFindOne, update as groupUpdate,
} from '../../services/groups';
import { Group } from '../../types/group';
import {
  addGroups, failGroup, fetchingFinishedGroup, fetchingGroup, updateGroup,
} from '../actions/groups';

export const findAll = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch(fetchingGroup());
  const response = await groupFindAll();
  const fetchedGroups: Group[] = await response.data.filter((g: Group) => g.is_owner);
  console.log(fetchedGroups);
  dispatch(addGroups(fetchedGroups));
  dispatch(fetchingFinishedGroup());
};

export const findOne = (id: number) => async (dispatch: Dispatch): Promise<void> => {
  const response = await groupFindOne(id);
  dispatch(updateGroup(response.data));
};

export const update = (group: Group) => async (dispatch: Dispatch): Promise<void> => {
  if (group.name === '' || group.description === '') {
    dispatch(failGroup({ status: '', message: 'グループ名と詳細分を入力してください' }));
    return;
  }
  dispatch(fetchingGroup());
  const response = await groupUpdate(group);
  dispatch(updateGroup(response.data));
  dispatch(push(`/groups/${response.data.id}`));
  // dispatch(updateGroup(response.data));
  // dispatch(setLoading({ status: false }));
};
