import axios from 'axios';
import { API_PATH } from './config';

class Invitation {
  // eslint-disable-next-line class-methods-use-this
  headers() {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
  }

  async invite(groupId: number, name: string, email: string) {
    return axios.post(`${API_PATH}/invitations`, {
      group_id: groupId,
      name,
      email,
    }, this.headers());
  }
}

export default new Invitation();
