import axios from 'axios';
import { API_PATH, API_AUTH_PATH } from './config';
import { Group } from '../types/group';

export function headers() {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
}

export async function findAll(): Promise<any> {
  return axios.get(`${API_PATH}/groups`, headers());
}

export async function findOne(id: number): Promise<any> {
  return axios.get(`${API_PATH}/groups/${id}`, headers());
}

export async function save(group: Group): Promise<any> {
  return axios.post(`${API_PATH}/groups`, group, headers());
}

export async function update(group: Group) {
  return axios.put(`${API_PATH}/groups/${group.id}`, group, headers());
}
