import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(config);

export async function login(email: string, password: string) {
  return firebase.auth()
    .signInWithEmailAndPassword(email, password).catch((err) => console.log(err));
}

export async function signup(email: string, password: string) {
  return firebase.auth()
    .createUserWithEmailAndPassword(email, password).catch((err) => console.log(err));
}

export async function logout() {
  localStorage.clear();
  return firebase.auth().signOut().catch((err) => console.log(err));
}

export function getUserState() {
  firebase.auth().onAuthStateChanged((user) => {
    return user;
  });
}
