import { User } from './user';
import { Board } from './board';
import { Calendar } from './calendar';
import { ErrorStatus } from './error_status';

export const GET_GROUPS = 'GET_GROUPS';
// export const GET_GROUP = 'GET_GROUP';
export const ADD_GROUPS = 'ADD_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const FETCHING_GROUP = 'FETCHING_GROUP';
export const FETCHING_FINISHED_GROUP = 'FETCHING_FINISHED_GROUP';
export const FAIL_GROUP_REQUEST = 'FAIL_GROUP_REQUEST';

export interface Group {
  id: number;
  name: string;
  description: string;
  // TODO(okubo): キャメルに直したい
  is_owner: boolean;
  users: User[];
  boards: Board[];
  calendars: Calendar[];
}

export interface GroupState {
  data: Group[];
  isFetching?: boolean;
  isError: ErrorStatus;
}

export interface GetGroupsAction {
  type: typeof GET_GROUPS
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload: {}
}

// export interface GetGroupAction {
//   type: typeof GET_GROUP
//   payload: { id: number }
// }

export interface AddGroupsAction {
  type: typeof ADD_GROUPS
  payload: { data: Group[] }
}

// export interface AddGroupAction {
//   type: typeof ADD_GROUP
//   payload: { data: Group }
// }

export interface UpdateGroupAction {
  type: typeof UPDATE_GROUP
  payload: { data: Group }
}

export interface DeleteGroupAction {
  type: typeof DELETE_GROUP
  payload: { id: number }
}

export interface FetchingGroupAction {
  type: typeof FETCHING_GROUP;
  payload: {};
}

export interface FetchingFinishedGroupAction {
  type: typeof FETCHING_FINISHED_GROUP;
  payload: {};
}

export interface FailGroupRequestAction {
  type: typeof FAIL_GROUP_REQUEST;
  payload: { error: ErrorStatus };
}

export type GroupActionTypes =
  GetGroupsAction
  // | GetGroupAction
  | AddGroupsAction
  | UpdateGroupAction
  | DeleteGroupAction
  | FetchingGroupAction
  | FetchingFinishedGroupAction
  | FailGroupRequestAction;
