import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FlashMessage, Loading } from '../../../components/common';
import { save as calendarSave, update as calendarUpdate } from '../../../redux/operations/calendars';

import { Group } from '../../../types/group';
import { Calendar } from '../../../types/calendar';
import { RootState } from '../../../types/store';
import { TextInput } from '../../../components/forms/TextInput';
import { FormLabel } from '../../../components/forms/FormLabel';
import { TextAreaInput } from '../../../components/forms/TextAreaInput';

const CalendarForm: React.FC = (props: any): JSX.Element => {
  const [group, setGroup] = useState({} as Group);
  const [calendar, setCalendar] = useState<Calendar>({} as Calendar);
  const getState = useSelector((state: RootState) => state);
  const { data } = useSelector((state: RootState) => state.groups);
  const { user } = useSelector((state: RootState) => state.session);

  const dispatch = useDispatch();

  useEffect(() => {
    const newGroup = data.find((g: Group) => g.id === Number(props.match.params.groupId));
    setGroup(newGroup);
    if (props.match.params.id !== 'new') {
      const newCalendar: Calendar = newGroup.calendars?.find(
        (c: Calendar) => c.id === Number(props.match.params.id),
      );
      newCalendar.start_at = new Date(newCalendar?.start_at);
      newCalendar.end_at = new Date(newCalendar?.end_at);
      setCalendar(newCalendar);
    }
  }, []);

  const add = async (e: any) => {
    e.preventDefault();
    dispatch(calendarSave(calendar));
  };

  const update = async (e: any) => {
    e.preventDefault();
    dispatch(calendarUpdate(calendar));
  };

  return (
    <Container>
      <Row className="my-4">
        <Col md="8" className="mx-auto">
          <h2 className="text-center">{calendar?.id === undefined ? 'カレンダー追加' : 'カレンダー修正'}</h2>
          { (getState.groups.isError.message !== '')
          && <FlashMessage variant="danger" text={getState.groups.isError.message} /> }
          <Form onSubmit={calendar?.id === undefined ? add : update}>
            <FormLabel name="タイトル" />
            <TextInput
              name="タイトル"
              value={calendar.title}
              onChange={(e: any) => setCalendar({
                id: calendar.id,
                title: e.target.value,
                description: calendar.description,
                url: calendar.url,
                start_at: calendar.start_at,
                end_at: calendar.end_at,
                user_id: user.id,
                group_id: group.id,
              } as Calendar)}
              placeholder="タイトル"
            />
            <FormLabel name="内容" />
            <TextAreaInput
              name="内容"
              value={calendar.description}
              onChange={(e: any) => setCalendar({
                id: calendar.id,
                title: calendar.title,
                description: e.target.value,
                url: calendar.url,
                start_at: calendar.start_at,
                end_at: calendar.end_at,
                user_id: user.id,
                group_id: group.id,
              } as Calendar)}
              placeholder="詳細"
            />
            <FormLabel name="URL" />
            <TextInput
              name="URL"
              value={calendar.url}
              onChange={(e: any) => setCalendar({
                id: calendar.id,
                title: calendar.title,
                description: calendar.description,
                url: e.target.value,
                start_at: calendar.start_at,
                end_at: calendar.end_at,
                user_id: user.id,
                group_id: group.id,
              } as Calendar)}
              placeholder="URL"
            />
            <div className="my-2">
              開始時間：
              <DatePicker
                selected={calendar.start_at}
                onChange={(date: any) => setCalendar({
                  id: calendar.id,
                  title: calendar.title,
                  description: calendar.description,
                  url: calendar.url,
                  start_at: date,
                  end_at: calendar.end_at,
                  user_id: user.id,
                  group_id: group.id,
                } as Calendar)}
                showTimeSelect
                dateFormat="yyyy-MM-dd HH:mm:ss"
              />
            </div>
            <div className="my-2">
              終了時間：
              <DatePicker
                selected={calendar.end_at}
                onChange={(date: any) => setCalendar({
                  id: calendar.id,
                  title: calendar.title,
                  description: calendar.description,
                  url: calendar.url,
                  start_at: calendar.start_at,
                  end_at: date,
                  user_id: user.id,
                  group_id: group.id,
                } as Calendar)}
                showTimeSelect
                dateFormat="yyyy-MM-dd HH:mm:ss"
              />
            </div>

            { getState.groups.isFetching
              ? <Loading message="送信しています..." />
              : (
                <Button className="btn-block" variant="primary" type="submit">
                  {calendar.id === undefined ? '追加' : '修正'}
                </Button>
              )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CalendarForm;
