import * as types from '../../types/group';
import { Group } from '../../types/group';

const initialState: types.GroupState = {
  data: [] as Group[],
  isFetching: false,
  isError: {
    status: '',
    message: '',
  },
};

export default function GroupReducer(
  state = initialState, action: types.GroupActionTypes,
): types.GroupState {
  switch (action.type) {
    case types.GET_GROUPS:
      return { ...state };
    case types.ADD_GROUPS: {
      const { data } = action.payload;
      return { ...state, data };
    }
    // 管理画面からGroupの追加はしないのでコメントアウト
    // case types.ADD_GROUP: {
    //   const { data } = action.payload;
    //   const clone = JSON.parse(JSON.stringify(state.data));
    //   clone.unshift(data);
    //   return { ...state, data: clone };
    // }
    case types.UPDATE_GROUP: {
      const { data } = action.payload;
      const clone = JSON.parse(JSON.stringify(state.data));
      const index = clone.findIndex((obj: any) => obj.id === data.id);
      if (index !== -1) clone[index] = data;
      return { ...state, data: clone };
    }
    case types.DELETE_GROUP: {
      const { id } = action.payload;
      const clone = JSON.parse(JSON.stringify(state.data));
      const index = clone.findIndex((obj: any) => obj.id === id);
      if (index !== -1) clone.splice(index, 1);
      return { ...state, data: clone };
    }
    case types.FETCHING_GROUP: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case types.FETCHING_FINISHED_GROUP: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case types.FAIL_GROUP_REQUEST: {
      const { error } = action.payload;
      return {
        ...state,
        isError: { status: error.status, message: error.message },
      };
    }
    default:
      return state;
  }
}
