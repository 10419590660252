import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import groupReducer from './groups';
import sessionReducer from './session';

const index = (history: History): any => combineReducers({
  groups: groupReducer,
  session: sessionReducer,
  router: connectRouter(history),
});

export default index;
